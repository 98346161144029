<template>
  <b-card-code
    title="Add New Product"
  >
    <b-form @submit.prevent="addProduct">
      <b-row>
        <b-col cols="12">
          <b-form-group
            label="Product Name"
            label-for="h-product-name"
            label-cols-md="4"
          >
            <b-form-input
              v-model="productName"
              id="h-product-name"
              placeholder="Product Name"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Manufactured"
            label-for="h-Manufactured"
            label-cols-md="4"
          >
            <b-form-input
              v-model="manufactured"
              id="h-Manufactured"
              placeholder="Manufactured"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Price"
            label-for="h-price"
            label-cols-md="4"
          >
            <b-form-input
              v-model="price"
              type="number"
              id="h-price"
              placeholder="Price"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Description"
            label-for="h-product-descr"
            label-cols-md="4"
          >
            <b-form-textarea
              v-model="descr"
              id="h-product-descr"
              placeholder="Textarea"
              rows="3"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Warranty (Month)"
            label-for="h-product-warranty"
            label-cols-md="4"
          >
            <b-form-input
              v-model="productWarranty"
              type="number"
              id="productWarranty"
              placeholder="Product Warranty"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Stock"
            label-for="h-product-stock"
            label-cols-md="4"
          >
            <b-form-input
              v-model="stock"
              type="number"
              id="h-product-stock"
              placeholder="Product stock"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Free Shipping"
            label-for="h-free-shipping"
            label-cols-md="4"
          >
            <b-form-checkbox
              v-model="freeShipping"
              :checked=this.freeShipping
              name="check-button"
              switch
              inline
            >
            </b-form-checkbox>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group
            label="Product Image"
            label-for="h-product-image"
            label-cols-md="4"
          >
            <!-- Accept all image formats by IANA media type wildcard-->
            <b-form-file
              id="h-product-image"
              accept="image/*"
              @change="onFile"
            />
          </b-form-group>
        </b-col>
        

        <!-- submit and reset -->
        <b-col offset-md="4">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class="mr-1"
          >
            Submit
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="outline-secondary"
          >
            Reset
          </b-button>
        </b-col>
      </b-row>

    </b-form>

   
  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BFormFile, 
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCardCode,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormFile,
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      productName : '',
      manufactured : '',
      price: '',
      descr: '',
      productWarranty: 12,
      stock: '',
      freeShipping: true,
      productImage: '',
      fdProductImage: '',
    }
  },
   methods: {

    onFile(e) {
      this.fdProductImage = e.target.files[0]
    },
    addProduct() {
      const { email } = this.$store.state.auth.ActiveUser
      const post_data = new FormData()
      post_data.append('productName', this.productName)
      post_data.append('manufactured', this.manufactured)
      post_data.append('price', this.price)
      post_data.append('descr', this.descr)
      post_data.append('productWarranty', this.productWarranty)
      post_data.append('stock', this.stock)
      post_data.append('freeShipping', this.freeShipping ? true : false)
      post_data.append('file', this.fdProductImage)


      this.$http
          .post(`/api/product/${email}`, post_data)
          .then(response => {
            const { success } = response.data
            const { message } = response.data
            this.isLoading = false
            if (success) {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Product Notification',
                    icon: 'AlertCircleIcon',
                    text: message,
                    variant: 'success',
                  },
                },
                { timeout: this.$longestTimeout },
              )
              this.$router.push({name: 'marketplace-manage-products'})
            } else {
              this.$toast(
                {
                  component: this.$toastContent,
                  props: {
                    title: 'Product Notification',
                    icon: 'AlertCircleIcon',
                    text: `Can't create product, try again later`,
                    variant: 'error',
                  },
                },
                { timeout: this.$longestTimeout },
              )
            }
          })
          .catch(error => {
            alert('ERROR')
          })
      
    }
  }
}
</script>
